.main-messenger-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.main-messenger-wrapper .inner {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  padding: 0px;
  background: transparent;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  position: relative;
}

.main-messenger-wrapper .mobile {
  padding-top: 38px;
}
